<template>
    <div>
        <div class="container" v-if="!isPublic && $route.name !== 'testing'">
            <div class="row justify-content-center">
                <div class="col-12 col-sm-9 custom-div ">
                    <b-card class="mt-3" >
                        <div class="text-center">
                            <h2>Prijave še niso odprte.</h2>
                        </div>
                    </b-card>
                </div>
            </div>
        </div>
        <div class="container mt-2" v-else>
            <template v-if="showSuccessCard">
                <b-card>
                    <div class="text-center">
                        <fa icon="check" size="6x" class="mb-2" style="color: #2ecc71"></fa>
                        <h1>Vloga za letovanje je bila uspešno oddana, otrok ima zagotovljeno mesto v izmeni, ki ste jo izbrali. Posebej pozorni bodite na izbrano izmeno – če je »redna« izmena ali »čakalna vrsta«. Prijava v »čakalno listo« posamezne izmene še ne pomeni zagotovljenega mesta v izmeni, ampak razporeditev v čakalno vrsto za to izmeno.</h1>
                        <h3>V kratkem boste na e-mail, ki ste ga vpisali v prijavnici, prejeli izpolnjeno vlogo. Prosimo, da preverite vse vnesene podatke in nam morebitne popravke posredujete po e-mailu <a>letovanjazpm@zpm-mb.si.</a></h3>
                    </div>
                </b-card>
            </template>
            <template v-else>

                <b-card>
                    <div class="text-center">
                        <h1>Pozdravljeni na prijavi otrok na letovanje Zveze prijateljev mladine Maribor</h1>
                    </div>
                </b-card>

                <!--hide-buttons-->
                <b-card body-class="mb-0">

                    <form-wizard
                        ref="refFormWizard"
                        color="#7367F0"
                        :title="null"
                        :subtitle="null"
                        finish-button-text="Oddaja"
                        back-button-text="Nazaj"
                        next-button-text="Naprej"
                        class="checkout-form-wizard steps-transparent"
                        @on-complete="onSubmitClicked">

                        <tab-content title="Korak 1">
                            <h3>Izbira letovanja</h3>
                            <b-form-group label="Tip letovanja">
                                <validation-provider #default="{ errors }" name="Tip letovanja" rules="required">
                                    <vue-select :options="termTypes" v-model="submissionObject.term_type" label="text"
                                                :clearable="false" :filterable="false" :searchable="false" :reduce="r => r.value"></vue-select>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </tab-content>

                        <tab-content title="Korak 2"
                                     :before-change="validateStepOne">
                            <h3>Izbira termina</h3>
                            <validation-observer ref="stepOneValidation">
                                <b-row>
                                    <b-col>
                                        <b-form-group
                                            label="Željena izmena"
                                            description="*letujejo srednješolci (do 17. leta) in starejši osnovnošolci. Vse izmenjave so tudi zdravstvene!. ZPM Maribor si pridrožuje pravico otroka uvrstiti v izmeno in skupino po lastnem izboru in presoji!"
                                        >
                                            <validation-provider name="Željena izmena" :rules="`required`">
                                                <b-row v-for="(group, idx) of availableTermsGrouped" v-bind:key="`group-${idx}`">
                                                    <b-col cols="12" md="6" v-for="term of group" v-bind:key="`term-radio-${term.id}`">
                                                        <b-form-radio v-model="submissionObject.term_id" :value="term.id"
                                                                      :disabled="term.available_slots <= 0" class="mt-05" >
                                                            <div class="d-flex">
                                                                <span><b>{{term.name}}<template v-if="term.high_school_only">*</template> </b> ({{ formatDate(term.date_from) }} - {{formatDate(term.date_to)}})</span>
                                                                <div class="ml-1">
                                                                    <b-badge v-if="term.available_slots > 0" variant="success">Prosto</b-badge>
                                                                    <b-badge v-else variant="danger">Zasedeno</b-badge>
                                                                </div>
                                                            </div>
                                                        </b-form-radio>
                                                    </b-col>

                                                </b-row>
                                                <small class="text-danger">{{ selectedTermErrorMessage }}</small>

                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12">
                                        <b-form-group
                                            label="Želje otroka za razvrstitev v skupino"
                                        >
                                            <validation-provider #default="{ errors }" name="Želje otroka za razvrstitev v skupino">
                                                <b-form-textarea v-model="submissionObject.classification_wish"></b-form-textarea>
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                            <h4 class="text-danger">Upoštevajo se največ 3 imena - otroci morajo biti istega spola in največ 1 leto in pol starostne razlike</h4>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col >
                                        <b-form-group label="Način letovanja" class="d-flex flex-wrap">
                                            <div class="d-flex flex-column">
                                                <validation-provider #default="{ errors }" name="Način letovanja" rules="required"  v-for="item in submissionTypes" :key="item.id">
                                                    <div class="pr-1 pb-1">
                                                        <b-form-radio v-model="submissionObject.submission_type" name="Način letovanja" :value="item.value"><b>{{item.text}}</b></b-form-radio>
                                                        <span>{{item.description}}</span>
                                                    </div>
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </div>
                                        </b-form-group>
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-col cols="12" md="6">
                                        <b-form-group label="Občina otrokovega stalnega prebivališča" >
                                            <validation-provider #default="{ errors }" name="Občina otrokovega stalnega prebivališča" rules="required">
                                                <vue-select :options="municipalitiesOptions" label="name" v-model="selectedMunicipality"
                                                            :clearable="false" :filterable="true" :searchable="true"></vue-select>
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                            <h4 v-if="(submissionObject.submission_type === 3 || submissionObject.submission_type === 4) && selectedMunicipality && selectedMunicipality.description" class="text-danger">{{selectedMunicipality.description}}</h4>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <files-component v-if="(submissionObject.submission_type === 3 || submissionObject.submission_type === 4) && selectedMunicipality && selectedMunicipality.child_allowance_required"
                                                 label="Dokazilo za subvencionirano letovanje (veljavna odločba o otroškem dodatku, izjemoma zadnja odločba dohodnine)" validation-name="Dokazilo o otroškem dodatku"
                                                 v-model="childAllowanceFiles" name="allowance"/>
                                <h4 v-else-if="(submissionObject.submission_type === 3 || submissionObject.submission_type === 4) && selectedMunicipality && !municipalityHasDescription
                                " class="text-danger">Občina ne sofinancira letovanja!</h4>
                            </validation-observer>
                        </tab-content>

                        <tab-content  title="Korak 3"
                                      :before-change="validateStepTwo">
                            <h3>Podatki o otroku</h3>
                            <validation-observer ref="stepTwoValidation">
                                <b-row>

                                    <b-col cols="12" md="5">
                                        <b-form-group label="Priimek">
                                            <validation-provider #default="{ errors }" name="Priimek" rules="required">
                                                <b-form-input v-model="submissionObject.last_name"></b-form-input>
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="12" md="5">
                                        <b-form-group label="Ime">
                                            <validation-provider #default="{ errors }" name="Ime" rules="required">
                                                <b-form-input v-model="submissionObject.first_name"></b-form-input>
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="12" md="2">
                                        <b-form-group label="Spol">
                                            <validation-provider #default="{ errors }" name="Spol" rules="required">
                                                <b-form-radio-group v-model="submissionObject.gender"
                                                                    :options="genders"></b-form-radio-group>
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>

                                <!--              EMŠO, ZZZS, Kraj rojstva-->
                                <b-row>
                                    <b-col cols="12" md="4">
                                        <b-form-group label="EMŠO">
                                            <validation-provider #default="{ errors }" name="EMŠO" rules="required|emso_rule">
                                                <b-form-input v-model="submissionObject.emso"></b-form-input>
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>

                                    </b-col>
                                    <b-col cols="12" md="4" v-if="submissionObject.submission_type === 2 || submissionObject.submission_type === 4">
                                        <b-form-group label="ZZZS številka">
                                            <validation-provider #default="{ errors }" name="ZZZS številka" rules="required|max_text:9">
                                                <b-form-input v-model="submissionObject.zzzs_number"></b-form-input>
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>

                                    <b-col cols="12" md="4">
                                        <b-form-group label="Kraj rojstva">
                                            <validation-provider #default="{ errors }" name="Kraj rojstva" rules="required">
                                                <b-form-input v-model="submissionObject.birth_place"></b-form-input>
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-col cols="12" md="4">
                                        <b-form-group label="Številka osebnega dokumenta" description="s katerim bo otrok prestopil mejo">
                                            <validation-provider #default="{ errors }" name="Številka osebnega dokumenta" rules="required">
                                                <b-form-input v-model="submissionObject.document_id"></b-form-input>
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>

                                    </b-col>
                                    <b-col cols="12" md="4">
                                        <b-form-group label="Datum izdaje dokumenta">
                                            <validation-provider #default="{ errors }" name="Datum izdaje dokumenta" rules="required">
                                                <flat-pickr class="form-control" v-model="submissionObject.document_date_issued" :config="flatpickrConfig"/>
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>

                                    <b-col cols="12" md="4">
                                        <b-form-group label="Tip dokumenta">
                                            <validation-provider #default="{ errors }" name="Document type" rules="required">
                                                <b-form-radio-group v-model="submissionObject.document_type"
                                                                    :options="document_types"></b-form-radio-group>
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="12" md="4">
                                        <b-form-group label="Kraj izdaje dokumenta">
                                            <validation-provider #default="{ errors }" name="Kraj izdaje dokumenta" rules="required">
                                                <b-form-input v-model="submissionObject.document_place_issued"></b-form-input>
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>

                                <Address ref="podatki-o-otroku-address" name="podatki-o-otroku" :address="submissionObject.address" :countries="countries" :postcodes="postcodes"/>

                                <b-row >
                                    <b-col cols="12" md="6">
                                        <b-form-group label="Državljanstvo">
                                            <validation-provider #default="{ errors }" name="Državljanstvo" rules="required">
                                                <vue-select :options="citizenships" v-model="citizenshipObject" label="descriptor"
                                                            :clearable="false" :filterable="true" :searchable="true" @input="handleCitizenship" >
                                                    <template #option="{descriptor,descriptor_english}">
                                                        <span>{{`${descriptor} - ${descriptor_english}`}}</span>
                                                    </template>
                                                    <template #selected-option="{descriptor,descriptor_english}">
                                                        <span v-if="descriptor || descriptor_english">{{`${descriptor} - ${descriptor_english}`}}</span>
                                                    </template>
                                                </vue-select>
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>

                                <hr>
                                <b-col cols="12" md="4">
                                    <b-form-group >
                                        <validation-provider #default="{ errors }" rules="required">
                                            <b-form-radio-group v-model="submissionObject.school_type"
                                                                :options="school_types"></b-form-radio-group>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-row>
                                    <b-col cols="12" md="8">
                                        <b-form-group label="Osnovna šola" description="Naziv in kraj šole" v-if="submissionObject.school_type === 2">
                                            <validation-provider #default="{ errors }" name="Osnovna šola" rules="required">
                                                <vue-select :options="primarySchools" v-model="primarySchoolObject" label="name" @input="handlePrimarySchool"
                                                            :clearable="false" :filterable="true" :searchable="true"></vue-select>
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                        <b-form-group label="Šola" description="Naziv in kraj šole" v-else>
                                            <validation-provider #default="{ errors }" name="Naziv in kraj šole" rules="required">
                                                <b-form-input v-model="submissionObject.school"></b-form-input>
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="12" md="4">
                                        <b-form-group label="Razred">
                                            <validation-provider #default="{ errors }" name="Razred" rules="required">
                                                <b-form-input v-model="submissionObject.class"></b-form-input>
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </validation-observer>
                        </tab-content>

                        <tab-content title="Korak 4" :before-change="validateStepThree">
                            <h3>Podatki o starših/skrbnikih</h3>
                            <validation-observer ref="stepThreeValidation">
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <b-card body-class="p-0">
                                            <b-row>
                                                <b-col class="d-flex flex-column flex-md-row justify-content-between">
                                                    <div class="d-flex">
                                                        <b-form-group>
                                                            <validation-provider #default="{ errors }" name="Skrbnik" >
                                                                <vue-select v-model="submissionObject.mother_data.parent_type" :options="removeLastType" :reduce="x => x.value" label="text" class="minWidth"
                                                                            :clearable="false" :filterable="false" :searchable="false" >
                                                                </vue-select>
                                                                <small class="text-danger">{{ errors[0] }}</small>
                                                            </validation-provider>
                                                        </b-form-group>
                                                    </div>
                                                    <b-badge variant="success" class="h-fit-content cursor-pointer minWidth" @click="onSetMotherAddressData">Prenesi podatke od otroka</b-badge>
                                                </b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col cols="12">
                                                    <b-form-group label="Priimek">
                                                        <validation-provider #default="{ errors }" name="Priimek mame" rules="required">
                                                            <b-form-input v-model="submissionObject.mother_data.last_name"></b-form-input>
                                                            <small class="text-danger">{{ errors[0] }}</small>
                                                        </validation-provider>
                                                    </b-form-group>
                                                </b-col>

                                                <b-col cols="12">
                                                    <b-form-group label="Ime">
                                                        <validation-provider #default="{ errors }" name="Ime mame"  rules="required">
                                                            <b-form-input v-model="submissionObject.mother_data.first_name"></b-form-input>
                                                            <small class="text-danger">{{ errors[0] }}</small>
                                                        </validation-provider>
                                                    </b-form-group>
                                                </b-col>

                                                <b-col cols="6">
                                                    <b-form-group label="Telefonska številka">
                                                        <validation-provider #default="{ errors }" name="Telefonska številka mame"  rules="min_text:9">
                                                            <b-form-input v-model="submissionObject.mother_data.phone_number"></b-form-input>
                                                            <small class="text-danger">{{ errors[0] }}</small>
                                                        </validation-provider>
                                                    </b-form-group>
                                                </b-col>

                                                <b-col cols="6">
                                                    <b-form-group label="Email">
                                                        <validation-provider #default="{ errors }" name="Email"  rules="email">
                                                            <b-form-input v-model="submissionObject.mother_data.email"></b-form-input>
                                                            <small class="text-danger">{{ errors[0] }}</small>
                                                        </validation-provider>
                                                    </b-form-group>
                                                </b-col>

                                            </b-row>
                                            <Address ref="mother-address" name="mother-address" :address="submissionObject.mother_data.address" :disabled="false" :countries="countries" :postcodes="postcodes"/>
                                            <b-col>
                                                <b-row cols="12" md="6">
                                                    <b-form-group label="Status">
                                                        <validation-provider #default="{ errors }" name="Status mame/skrbnika"  rules="required">
                                                            <b-form-radio-group v-model="submissionObject.mother_data.parent_status"
                                                                                :options="parent_statuses" :disabled="!submissionObject.mother_data.parent_exists"></b-form-radio-group>
                                                            <small class="text-danger">{{ errors[0] }}</small>
                                                        </validation-provider>
                                                    </b-form-group>
                                                </b-row>
                                            </b-col>
                                        </b-card>
                                    </b-col>
                                    <b-col cols="12" md="6">
                                        <b-card body-class="p-0">
                                            <b-row>
                                                <b-col class="d-flex flex-column flex-md-row justify-content-between">
                                                    <div class="d-flex">
                                                        <b-form-group>
                                                            <validation-provider #default="{ errors }" name="Pošta" >
                                                                <vue-select v-model="submissionObject.father_data.parent_type" :options="parentTypes" :reduce="x => x.value" label="text" class="minWidth"
                                                                            :clearable="false" :filterable="false" :searchable="false" >
                                                                </vue-select>
                                                                <small class="text-danger">{{ errors[0] }}</small>
                                                            </validation-provider>
                                                        </b-form-group>
                                                    </div>
                                                    <b-badge variant="success" class="h-fit-content cursor-pointer minWidth" @click=" submissionObject.father_data.parent_type !== 5 ? onSetFatherAddressData() : ()=>{}">Prenesi podatke od otroka</b-badge>
                                                </b-col>
                                            </b-row>

                                            <b-row>
                                                <b-col cols="12">
                                                    <b-form-group label="Priimek">
                                                        <validation-provider #default="{ errors }" name="Priimek očeta" :rules="submissionObject.father_data.parent_type !== 5 ? 'required':''">
                                                            <b-form-input v-model="submissionObject.father_data.last_name" :disabled="submissionObject.father_data.parent_type === 5"></b-form-input>
                                                            <small class="text-danger">{{ errors[0] }}</small>
                                                        </validation-provider>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col cols="12">
                                                    <b-form-group label="Ime">
                                                        <validation-provider #default="{ errors }" name="Ime očeta"  :rules="submissionObject.father_data.parent_type !== 5 ? 'required':''">
                                                            <b-form-input v-model="submissionObject.father_data.first_name" :disabled="submissionObject.father_data.parent_type === 5"></b-form-input>
                                                            <small class="text-danger">{{ errors[0] }}</small>
                                                        </validation-provider>
                                                    </b-form-group>
                                                </b-col>

                                                <b-col cols="6">
                                                    <b-form-group label="Telefonska številka">
                                                        <validation-provider #default="{ errors }" name="Telefonska številka očeta" :rules="submissionObject.father_data.parent_type !== 5 ? 'min_text:9':''">
                                                            <b-form-input v-model="submissionObject.father_data.phone_number" :disabled="submissionObject.father_data.parent_type === 5"></b-form-input>
                                                            <small class="text-danger">{{ errors[0] }}</small>
                                                        </validation-provider>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col cols="6">
                                                    <b-form-group label="Email">
                                                        <validation-provider #default="{ errors }" name="Email" :rules="submissionObject.father_data.parent_type !== 5 ? 'email':''">
                                                            <b-form-input v-model="submissionObject.father_data.email" :disabled="submissionObject.father_data.parent_type === 5"></b-form-input>
                                                            <small class="text-danger">{{ errors[0] }}</small>
                                                        </validation-provider>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                            <Address ref="father-address" name="father-address" :address="submissionObject.father_data.address" :countries="countries" :postcodes="postcodes" :disabled="submissionObject.father_data.parent_type === 5"/>
                                            <b-col>
                                                <b-row cols="12" md="6">
                                                    <b-form-group label="Status ">
                                                        <validation-provider #default="{ errors }" name="Status očeta/skrbnika" :rules="submissionObject.father_data.parent_type !== 5 ? 'required':''">
                                                            <b-form-radio-group v-model="submissionObject.father_data.parent_status"
                                                                                :options="parent_statuses" :disabled="submissionObject.father_data.parent_type === 5"></b-form-radio-group>
                                                            <small class="text-danger">{{ errors[0] }}</small>
                                                        </validation-provider>
                                                    </b-form-group>
                                                </b-row>
                                            </b-col>
                                        </b-card>
                                    </b-col>
                                </b-row>
                                <hr>
                            </validation-observer>
                        </tab-content>

                        <tab-content title="Korak 5" :before-change="validateStepFour">
                            <b-row>
                                <b-col class="d-flex justify-content-between">
                                    <h3>Plačnik, način plačila in naslov za pošiljanje pošte</h3>
                                    <div>
                                        <span>Prenesi podatke od: </span>
                                        <b-badge variant="success" class="h-fit-content cursor-pointer ml-1" @click="onSetPayeeData('mother')">{{getParentTypeText(submissionObject.mother_data.parent_type)}}</b-badge>
                                        <b-badge variant="success" class="h-fit-content cursor-pointer ml-1" v-if="submissionObject.father_data.parent_type !== 5" @click="onSetPayeeData('father')">{{getParentTypeText(submissionObject.father_data.parent_type)}}</b-badge>
                                        <b-badge variant="success" class="h-fit-content cursor-pointer ml-1" @click="onSetPayeeData('other')">Drugo</b-badge>
                                    </div>
                                </b-col>
                            </b-row>
                            <validation-observer ref="stepFourValidation">
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <b-form-group label="Priimek">
                                            <validation-provider #default="{ errors }" name="Priimek plačnika" rules="required">
                                                <b-form-input v-model="submissionObject.payee_last_name"></b-form-input>
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="12" md="6">
                                        <b-form-group label="Ime">
                                            <validation-provider #default="{ errors }" name="Ime plačnika" rules="required">
                                                <b-form-input v-model="submissionObject.payee_first_name"></b-form-input>
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>

                                </b-row>

                                <Address ref="payee-address" name="payee-address" :address="submissionObject.payee_address" :countries="countries" :postcodes="postcodes"/>

                                <b-row>
                                    <b-col cols="12" md="6">
                                        <b-form-group label="E-naslov"
                                                      description="Za pošiljanje računov, pogodb in obvestil v elektronski obliki">
                                            <validation-provider #default="{ errors }" name="E-naslov plačnika" rules="required|email">
                                                <b-form-input v-model="submissionObject.payee_email"></b-form-input>
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="12" md="6">
                                        <b-form-group label="Davčna številka">
                                            <!--                  TODO - DAVČNA ŠTEVILKA VALIDATE -->
                                            <validation-provider #default="{ errors }" name="Davčna številka plačnika" rules="required|tax_rule">
                                                <b-form-input v-model="submissionObject.payee_tax_number"></b-form-input>
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>

                                <hr>

                                <b-row>
                                    <b-col cols="12" md="auto">
                                        <validation-provider #default="{ errors }" name="Način plačila" rules="required">
                                            <b-form-group label="Način plačila" >
                                                <b-form-radio-group v-model="submissionObject.payment_type" :options="paymentOptions"></b-form-radio-group>
                                            </b-form-group>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-col>
                                    <b-col>
                                        <validation-provider #default="{ errors }" name="Število obrokov" rules="required">
                                            <b-form-group label="Število obrokov" >
                                                <b-form-radio-group v-model="submissionObject.number_of_installments"
                                                                    :options="submissionObject.payment_type === 4 ? numberOfInstallmentsTypeTwo : numberOfInstallmentsTypeOne"></b-form-radio-group>
                                            </b-form-group>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-col>
                                </b-row>
                                <b-row v-if="submissionObject.payment_type === 4">
                                    <b-col cols="12" md="4">
                                        <validation-provider #default="{ errors }" name="Št. Transakcijskega Računa" rules="required">
                                            <b-form-group label="Št. Transakcijskega Računa" >
                                                <b-form-input v-model="submissionObject.direct_debit_trr"/>
                                            </b-form-group>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-col>
                                    <b-col cols="12" md="4">
                                        <validation-provider #default="{ errors }" name="Naziv banke" rules="required">
                                            <b-form-group label="Naziv banke" >
                                                <b-form-input v-model="submissionObject.direct_debit_bank_name"/>
                                            </b-form-group>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-col>
                                    <b-col cols="12" md="4">
                                        <validation-provider #default="{ errors }" name="Dan odtegljaja" rules="required">
                                            <b-form-group label="Dan odtegljaja" >
                                                <vue-select v-model="submissionObject.direct_debit_day" :options="directDebitDays"
                                                            :clearable="false" :filterable="false" :searchable="false"/>
                                            </b-form-group>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-col>
                                </b-row>
                            </validation-observer>
                        </tab-content>

                        <tab-content title="Korak 6" :before-change="validateStepFive">
                            <validation-observer ref="stepFiveValidation">
                                <h3>Naslov za obveščanje</h3>
                                <b-row>
                                    <b-col cols="12" md="5">
                                        <div class="d-flex justify-content-between" >
                                            <legend class="bv-no-focus-ring col-form-label pt-0" style="width: fit-content;">Vedno dosegljiv telefon</legend>
                                            <div class="mb-1">
                                                <div>Prenesi od: </div>
                                                <div>
                                                    <b-badge @click="setReachablePhoneNumber('mother')" class="h-fit-content cursor-pointer"  v-if="submissionObject.mother_data.phone_number !== ''" variant="success">{{getParentTypeText(submissionObject.mother_data.parent_type)}}</b-badge>
                                                    <b-badge @click="setReachablePhoneNumber('father')" class="h-fit-content cursor-pointer ml-1" v-if="submissionObject.father_data.parent_type !== 5 && submissionObject.father_data.phone_number !== ''" variant="success">{{getParentTypeText(submissionObject.father_data.parent_type)}}</b-badge>
                                                </div>
                                            </div>
                                        </div>
                                        <b-form-group :class="submissionObject.mother_data.phone_number  && submissionObject.mother_data.phone_number === ''?'mt-2':''">
                                            <validation-provider #default="{ errors }" name="Vedno dosegljiv telefon" rules="required|min_text:9">
                                                <b-form-input v-model="submissionObject.reachable_phone_number"></b-form-input>
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="12" md="5">
                                        <div class="d-flex justify-content-between">
                                            <legend class="bv-no-focus-ring col-form-label pt-0" style="width: fit-content;">Elektronski naslov starša/skrbnika</legend>
                                            <div class="mb-1">
                                                <div>Prenesi od: </div>
                                                <div>
                                                    <b-badge @click="setReachableEmail('mother')" class="h-fit-content cursor-pointer" v-if="submissionObject.mother_data.email !== ''" variant="success">{{getParentTypeText(submissionObject.mother_data.parent_type)}}</b-badge>
                                                    <b-badge @click="setReachableEmail('father')" class="h-fit-content cursor-pointer ml-1" variant="success" v-if="submissionObject.father_data.parent_type !== 5 && submissionObject.father_data.email !== ''">{{getParentTypeText(submissionObject.father_data.parent_type)}}</b-badge>
                                                    <b-badge @click="setReachableEmail('payee')" class="h-fit-content cursor-pointer ml-1" variant="success">Plačnika</b-badge>

                                                </div>
                                            </div>
                                        </div>
                                        <b-form-group>

                                            <validation-provider #default="{ errors }" name="Elektronski naslov starša/skrbnika" rules="required|email">
                                                <b-form-input v-model="submissionObject.reachable_parent_email"></b-form-input>
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                        <b-form-checkbox v-model="submissionObject.reachable_parent_email_allow_news_letters">Dovoljenje za prejemanje novic</b-form-checkbox>
                                    </b-col>
                                </b-row>
                                <b-row class="pb-5">
                                    <b-col cols="12" md="4">
                                        <div class="d-flex justify-content-between">
                                            <legend class="bv-no-focus-ring col-form-label pt-0" style="width: fit-content;">Elektronski naslov otroka</legend>
                                            <div class="mb-1">
                                                <div><br/></div>
                                            </div>
                                        </div>
                                        <b-form-group description="Z navedbo e-naslova pristajam, da mene in mojega otroka ZPM Maribor informira o novostih v zvezi z letovanjem in obvešča o drugih programih ZPM Maribor">
                                            <validation-provider #default="{ errors }" name="Elektronski naslov otroka" rules="email">
                                                <b-form-input v-model="submissionObject.reachable_child_email"></b-form-input>
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                        <b-form-checkbox v-model="submissionObject.reachable_child_email_allow_news_letters">Dovoljenje za prejemanje novic</b-form-checkbox>
                                    </b-col>
                                </b-row>
                            </validation-observer>
                        </tab-content>

                        <tab-content title="Korak 7" :before-change="validateStepSix">
                            <h3>Otrokov osebni zdravnik (šolski ali izbrani)</h3>
                            <validation-observer ref="stepSixValidation">
                                <b-col cols="12" md="4">
                                    <b-form-checkbox
                                        class="mt-auto mb-auto"
                                        v-model="ownDoctorEntry"
                                        inline
                                    >Otrokov osebni zdravnik je na seznamu</b-form-checkbox>

                                </b-col>


                                <b-row class="mt-2">
                                    <b-col cols="12" sm="4" md="6" v-if="ownDoctorEntry">
                                        <b-form-group label="Zdravniki" >
                                            <validation-provider #default="{ errors }" name="Zdravniki" rules="required">
                                                <vue-select :options="doctors" v-model="submissionObject.doctor_id" label="first_name" :reduce="x => x.id" :disabled="!ownDoctorEntry"
                                                            :clearable="false" :filterable="true" :searchable="true"
                                                            :filterBy="searchDoctors">
                                                    <template #option="{first_name,last_name,title_1}">
                                                        <span>{{`${last_name} ${first_name} ${title_1}`}}</span>
                                                    </template>
                                                    <template #selected-option="{first_name,last_name,title_1}">
                                                        <span>{{`${last_name} ${first_name} ${title_1}`}}</span>
                                                    </template>
                                                </vue-select>
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <template v-else>
                                        <b-col cols="12" md="4">
                                            <validation-provider #default="{ errors }" name="Priimek zdravnika" rules="required">
                                                <b-form-group label="Priimek zdravnika" >
                                                    <b-form-input v-model="submissionObject.doctor_last_name" />
                                                </b-form-group>
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-col>
                                        <b-col cols="12" md="4">
                                            <validation-provider #default="{ errors }" name="Ime zdravnika" rules="required">
                                                <b-form-group label="Ime zdravnika" >
                                                    <b-form-input v-model="submissionObject.doctor_first_name" />
                                                </b-form-group>
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-col>
                                        <b-col cols="12" md="4">
                                            <validation-provider #default="{ errors }" name="Naziv zdravstvene ustanove/ambulante" rules="required">
                                                <b-form-group label="Naziv zdravstvene ustanove/ambulante" >
                                                    <b-form-input v-model="submissionObject.doctor_institution" />
                                                </b-form-group>
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-col>
                                    </template>
                                </b-row>
                            </validation-observer>
                        </tab-content>

                        <tab-content title="Korak 8" :before-change="validateStepSeven">
                            <h3>Posebnosti otroka</h3>
                            <validation-observer ref="stepSevenValidation">
                                <b-row>
                                    <b-col cols="12">
                                        <b-form-group label="Posebnosti otroka, na katere morajo biti spremljevalci pozorni">
                                            <b-form-radio-group v-model="hasSpecialCharacteristics" :options="choiceTypes"/>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row v-if="hasSpecialCharacteristics">
                                    <b-col cols="12">
                                        <validation-provider #default="{ errors }" name="Posebnosti otroka" rules="required">
                                            <b-form-group>
                                                <b-form-textarea v-model="submissionObject.special_characteristics"/>
                                            </b-form-group>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" md="6" class="pt-md-2">
                                        <b-form-group label="Alergije">
                                            <b-form-radio-group v-model="hasDietaryRestriction" :options="choiceTypes"/>
                                        </b-form-group>
                                        <validation-provider v-if="hasDietaryRestriction" #default="{ errors }" name="Alergije" rules="required">
                                            <b-form-group>
                                                <b-form-input v-model="submissionObject.dietary_restriction"/>
                                            </b-form-group>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-col>
                                    <b-col cols="12" md="6">
                                        <b-badge variant="warning">Obvezno opisati morebitne medicinske diete</b-badge>
                                        <validation-provider #default="{ errors }" name="Prehrana" rules="required">
                                            <b-form-group label="Prehrana" >
                                                <b-form-radio-group v-model="submissionObject.dietary_type"
                                                                    :options="dietaryTypes" @input="handleOtherDietaryRestrictions"></b-form-radio-group>
                                            </b-form-group>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                        <validation-provider v-if="submissionObject.dietary_type === 1" #default="{ errors }" name="Drugo" rules="required">
                                            <b-form-group>
                                                <b-form-input v-model="submissionObject.other_dietary_restriction"/>
                                            </b-form-group>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12">
                                        <b-form-group label="Psihosomatske posebnosti">
                                            <b-form-radio-group v-model="hasDisease" :options="choiceTypes"/>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row v-if="hasDisease">
                                    <b-col cols="12">
                                        <validation-provider #default="{ errors }" name="Psihosomatske posebnosti" rules="required">
                                            <b-form-group description="Terapije, ..." >
                                                <b-form-textarea v-model="submissionObject.disease"/>
                                            </b-form-group>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12">
                                        <b-form-group label="Bolezenska stanja">
                                            <b-form-radio-group v-model="hasDiseaseStates" :options="choiceTypes"/>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row v-if="hasDiseaseStates">
                                    <b-col cols="12">
                                        <validation-provider #default="{ errors }" name="Bolezenska stanja" rules="required">
                                            <b-form-group label="Bolezenska stanja">
                                                <b-form-textarea v-model="submissionObject.disease_states"/>
                                            </b-form-group>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-col>
                                </b-row>


                                <b-row>
                                    <b-col cols="12">
                                        <b-form-group label="Ali redno prejema zdravila">
                                            <b-form-radio-group v-model="submissionObject.has_medications" :options="choiceTypes"/>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row v-if="submissionObject.has_medications">
                                    <b-col cols="12">
                                        <validation-provider #default="{ errors }" name="Ali redno prejema zdravila" rules="required">
                                            <b-form-group>
                                                <b-form-textarea v-model="submissionObject.medications"/>
                                            </b-form-group>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-col>
                                </b-row>


                                <b-row>
                                    <b-col cols="12">
                                        <b-form-group label="Vzgojne in druge posebnosti otroka">
                                            <b-form-radio-group v-model="hasPeculiarities" :options="choiceTypes"/>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row v-if="hasPeculiarities">
                                    <b-col cols="12">
                                        <validation-provider #default="{ errors }" name="Vzgojne in druge posebnosti otroka" rules="required">
                                            <b-form-group>
                                                <b-form-textarea v-model="submissionObject.peculiarities"/>
                                            </b-form-group>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-col cols="12">
                                        <validation-provider #default="{ errors }" name="Odločba o usmerjenosti otroka s posebnimi potrebami" rules="required">
                                            <b-form-group label="Odločba o usmerjenosti otroka s posebnimi potrebami">
                                                <b-form-radio-group v-model="submissionObject.special_needs_order"
                                                                    :options="specialNeedsOrderTypes"></b-form-radio-group>
                                            </b-form-group>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-col>
                                </b-row>
                                <b-row v-if="submissionObject.special_needs_order === true">
                                    <b-col cols="12">
                                        <validation-provider #default="{ errors }" name="Vrsta primanjkljaja, ovire oziroma motnje" rules="required">
                                            <b-form-group label="Vrsta primanjkljaja, ovire oziroma motnje">
                                                <b-form-textarea v-model="submissionObject.special_needs_description"/>
                                            </b-form-group>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-col cols="12" md="6">
                                        <validation-provider #default="{ errors }" name="Zna plavati" rules="required">
                                            <b-form-group label="Zna plavati">
                                                <b-form-radio-group v-model="submissionObject.swimming_skills_level"
                                                                    :options="swimmingSkillsTypes"></b-form-radio-group>
                                            </b-form-group>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-col cols="12">
                                        <validation-provider #default="{ errors }" name="Odločba o prepovedi stikov" rules="required">
                                            <b-form-group label="Odločba o prepovedi stikov">
                                                <b-form-radio-group v-model="submissionObject.restraining_order_type"
                                                                    :options="restrainingOrderTypes"></b-form-radio-group>
                                            </b-form-group>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-col>
                                </b-row>

                                <files-component v-if="submissionObject.restraining_order_type !== 1"
                                                 label="Odločba o prepovedi stikov" validation-name="Odločba o prepovedi stikov"
                                                 v-model="restrainingOrderFiles" name="restrainingOrder"/>


                                <b-row>
                                    <b-col cols="12" md="6">
                                        <validation-provider #default="{ errors }" name="Način bivanja" rules="required">
                                            <b-form-group label="Ali otrok biva v vzgojnem ali drugem zavodu, mladinskem domu, ipd.">
                                                <b-form-radio-group v-model="submissionObject.child_lives_in_institution"
                                                                    :options="childLivesInInstitution"></b-form-radio-group>
                                            </b-form-group>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-col>
                                    <b-col cols="12" md="12">
                                        <b-row>
                                            <b-col cols="12" md="6" v-if="submissionObject.child_lives_in_institution">
                                                <validation-provider #default="{ errors }" name="Naziv ustanove" rules="required">
                                                    <b-form-group label="Naziv ustanove" >
                                                        <b-form-input v-model="submissionObject.child_care_institution"/>
                                                    </b-form-group>
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-col>
                                            <b-col cols="12" md="6" v-if="submissionObject.child_lives_in_institution">
                                                <validation-provider #default="{ errors }" name="Kontaktna oseba" rules="required">
                                                    <b-form-group label="Kontaktna oseba" >
                                                        <b-form-input v-model="submissionObject.child_care_contact_info"/>
                                                    </b-form-group>
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-col>
                                        </b-row>
                                    </b-col>

                                </b-row>
                            </validation-observer>
                        </tab-content>

                        <tab-content title="Opombe">
                            <h3>Ali nam želite še kaj posebej sporočiti?</h3>
                            <b-row>
                                <b-col>
                                    <validation-provider #default="{ errors }" name="Opomba">
                                        <b-form-group label="Opomba" >
                                            <b-form-input v-model="submissionObject.additional_message"/>
                                        </b-form-group>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-col>
                            </b-row>
                            <div class="d-flex justify-content-center mt-1">
                                <div id="cf-turnstile"></div>
                            </div>
                        </tab-content>

                        <tab-content title="Oddaja">
                            <h3>Zaključek in oddaja</h3>
                            <b-row v-if="showTermSelection">
                                <b-col>
                                    <b-alert show variant="warning" class="text-center p-2">Izbran termin je že zaseden, prosimo da izberite drugega in ponovno oddate prijavnico. </b-alert>

                                    <b-form-group
                                        label="Željena izmena"
                                        description="*letujejo srednješolci (do 17. leta) in starejši osnovnošolci. Vse izmenjave so tudi zdravstvene!. ZPM Maribor si pridrožuje pravico otroka uvrstiti v izmeno in skupino po lastnem izboru in presoji!"
                                    >
                                        <validation-provider name="Željena izmena" :rules="`required`">
                                            <b-row v-for="(group, idx) of availableTermsGrouped" v-bind:key="`group-${idx}`">
                                                <b-col cols="12" md="6" v-for="term of group" v-bind:key="`term-radio-${term.id}`">
                                                    <b-form-radio v-model="submissionObject.term_id" :value="term.id"
                                                                  :disabled="term.available_slots <= 0" class="mt-05" >
                                                        <div class="d-flex">
                                                            <span><b>{{term.name}}<template v-if="term.high_school_only">*</template> </b> ({{ formatDate(term.date_from) }} - {{formatDate(term.date_to)}})</span>
                                                            <div class="ml-1">
                                                                <b-badge v-if="term.available_slots > 0" variant="success">Prosto</b-badge>
                                                                <b-badge v-else variant="danger">Zasedeno</b-badge>
                                                            </div>
                                                        </div>
                                                    </b-form-radio>
                                                </b-col>

                                            </b-row>
                                            <small class="text-danger">{{ selectedTermErrorMessage }}</small>

                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <h4>S podpisom te PRIJAVE in IZJAVE:</h4>
                                    <p><strong>a.</strong> izjavljam, da za potrebe prijave na letovanje in nadaljevalnih postopkov (priprava pogodbe, priprava in izvedba letovanja), dovoljujem Zvezi prijateljev mladine (ZPM) Maribor obdelavo vseh posredovanih osebnih podatkov skladno z zakonodajo. Razumem, da ZPM Maribor jamči za varstvo posredovanih podatkov v skladu z veljavno zakonodajo o varovanju osebnih podatkov.
                                    </p>
                                    <p><strong>b.</strong> se obvezujem, da bom stroške letovanja poravnal na dogovorjen način in v dogovorjenem roku kot bo navedeno na obvestilu, ki ga bom po e-pošti na moj navedeni naslov prejel skupaj z izračunom za plačilo.
                                    </p>
                                    <p><strong>c.</strong> se strinjam, da je lahko otrok, v kolikor na letovanju zboli ali se poškoduje, zdravljen po principih uradne medicine.<br> V kolikor otrok ni cepljen po programu cepljenja otrok v Republiki Sloveniji, se strinjam, da bom v primeru otrokove poškodbe ali bolezni, na poziv, ki ga bom v takem primeru dobil s strani zdravstvenega oziroma pedagoškega spremstva otrok na letovanjih, osebno (v lastni režiji) nemudoma prišel po otroka in ga odpeljal z letovanja domov. To izjavo podpisujem na zahtevo organizatorja letovanja ZPM Maribor in se zavedam, da lahko organizator zaradi moje morebitne odklonitve podpisa iste izjave odkloni sprejem otroka na letovanje.</p>

                                    <p><strong>d.</strong> dovoljujem, da ZPM Maribor pridobi vse potrebne zdravstvene podatke o otroku iz otrokove zdravstvene kartoteke za potrebe zdravstva na letovanju; vključno z morebitnim posebnim mnenjem zdravnika, da je otrok primeren za vključitev na letovanje. Vpogled v podatke o zdravstvenem stanju je dovoljen strokovni službi ZPM Maribor in spremljajočemu zdravstvenemu osebju na letovanju.
                                    </p>
                                    <p><strong>e.</strong>dovoljujem mojemu otroku potovanje v tujino in s tem prehod državne meje v organizaciji ZPM Maribor in sem seznanjen, da za prehod državne meje potrebuje otrok veljaven potni list ali osebno izkaznico.  </p>
                                    <p><strong>f.</strong> izjavljam, da sem seznanjen z dejstvom, da bo moj otrok letoval v spremstvu prostovoljnih sodelavcev ZPM Maribor in z lastnim podpisom sprejemam splošne pogoje poslovanja ter hišni red za otroke na letovanju, dovoljujem fotografiranje in snemanje otroka, uporabo teh materialov za potrebe ZPM Maribor in tudi za javno objavo. </p>
                                    <p><strong>g.</strong> izjavljam, da sem seznanjen s pogoji poslovanja in hišnim redom za otroke na letovanju (v tiskani obliki kot priloga pogodbe, javna objava na sedežu ZPM Maribor in na spletni strani ZPM Maribor) in se zavezujem, da bom nemudoma prišel po otroka, če mu bo zaradi zdravstvenih ali epidemioloških razlogov oziroma kršenja hišnega reda in neprimernega vedenja na letovanju izrečen ZDRAVSTVENI ali VZGOJNI UKREP NAPOTITVE DOMOV.</p>

                                    <p> Prijavitelj s svojim podpisom zagotavljam resničnost navedenih podatkov in zanje v celoti odgovarjam. Strinjam se, da lahko ZPM Maribor te podatke uporablja v skladu s svojimi pravilniki. Kot moj podpis velja tudi poslana prijava preko spletne aplikacije ZPM Maribor za prijavo na letovanje.</p>
                                    <p><i>ZPM Maribor jamči za varstvo posredovanih podatkov v skladu z veljavno zakonodajo o varovanju osebnih podatkov. </i></p>
                                    <div class="d-md-flex">
                                        <div>
                                            <h5>Datum prijave</h5>
                                            <p>{{$dayjs(submissionObject.date_created).format('DD.MM.YYYY HH:mm:ss')}}</p>
                                        </div>
                                    </div>
                                </b-col>
                            </b-row>
                            <div class="d-flex justify-content-center mt-1">
                                <div id="cf-turnstile"></div>
                            </div>
                        </tab-content>
                    </form-wizard>
                </b-card>
            </template>
            <component v-bind:is="`script`" src="https://challenges.cloudflare.com/turnstile/v0/api.js?onload=onloadTurnstileCallback" async defer></component>
        </div>
    </div>
</template>

<script>
    import {
        BAlert,
        BBadge,
        BCard,
        BCol,
        BFormCheckbox,
        BFormGroup,
        BFormInput,
        BFormRadio,
        BFormRadioGroup,
        BFormTextarea,
        BRow
    } from 'bootstrap-vue'
    import VueSelect from 'vue-select'
    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import {email, emso_rule, max_text, min_text, required, tax_rule} from '@validations'
    import flatPickr from 'vue-flatpickr-component'
    import 'flatpickr/dist/flatpickr.css'
    import Address from '@/views/components/Address.vue'
    import {FormWizard, TabContent} from 'vue-form-wizard'
    import FilesComponent from '@/views/components/FilesComponent.vue'

    export default {
        components: {
            BAlert,
            BCard,
            BFormGroup,
            BFormInput,
            BRow,
            BCol,
            BFormRadioGroup,
            BFormTextarea,
            BFormRadio,
            BBadge,
            VueSelect,
            ValidationProvider,
            ValidationObserver,
            flatPickr,
            Address,
            FormWizard,
            TabContent,
            FilesComponent,
            BFormCheckbox
        },
        data() {
            return {
                submissionObject: {
                    // id:'',
                    term_type: 1,
                    term_id: -1,
                    submission_type: null,
                    submission_proof_attached:'',
                    submission_proof_attachment_name:'',
                    first_name:'',
                    last_name:'',
                    gender:null,
                    emso:'',
                    birth_place:'',
                    zzzs_number:'',
                    document_id:'',
                    document_type: null,
                    document_date_issued: '',
                    document_place_issued:'',
                    citizenship:'',
                    citizenship_code: '',
                    citizenship_id:null,
                    address:{
                        street: '',
                        street_number: '',
                        zip_id:null,
                        zip: '',
                        city: '',
                        country: '',
                        country_id: null
                    },
                    municipality_id: 1,
                    school:'',
                    school_id:null,
                    school_type:1,
                    class:'',
                    classification_wish:'',
                    child_allowance_document_name:'',
                    child_allowance_document_names: [],
                    mother_data: {
                        first_name: '',
                        last_name: '',
                        address:{
                            street: '',
                            street_number: '',
                            zip_id:null,
                            zip: '',
                            city: '',
                            country: '',
                            country_id: null
                        },
                        phone_number: '',
                        email: '',
                        parent_status:null,
                        parent_exists:true,
                        parent_type:1
                    },
                    father_data: {
                        first_name: '',
                        last_name: '',
                        address:{
                            street: '',
                            street_number: '',
                            zip_id:null,
                            zip: '',
                            city: '',
                            country: '',
                            country_id: null
                        },
                        phone_number: '',
                        email: '',
                        parent_status:null,
                        parent_exists:true,
                        parent_type:2
                    },
                    reachable_phone_number:'',
                    reachable_child_email:'',
                    reachable_parent_email:'',
                    reachable_child_email_allow_news_letters: false,
                    reachable_parent_email_allow_news_letters: false,
                    payee_first_name:'',
                    payee_last_name:'',
                    payee_address:{
                        street: '',
                        street_number: '',
                        zip_id:null,
                        zip: '',
                        city: '',
                        country: '',
                        country_id: null,
                        parent_type:1
                    },
                    payee_email:'',
                    payee_tax_number:'',
                    payment_type:3,
                    number_of_installments:1,
                    direct_debit_day:8,
                    direct_debit_trr:'',
                    direct_debit_bank_name:'',
                    doctor_first_name:'',
                    doctor_last_name:'',
                    doctor_institution:'',
                    doctor_id:null,
                    special_characteristics:'',
                    dietary_restriction:'',
                    other_dietary_restriction:'',
                    dietary_type:1,
                    disease_states:'',
                    disease:'',
                    peculiarities:'',
                    has_medications:true,
                    medications:'',
                    special_needs_order: true,
                    special_needs_description:'',
                    swimming_skills_level: null,
                    restraining_order_type: 1,
                    restraining_order_attachment_name:'',
                    restraining_order_attachment_names: [],
                    child_lives_in_institution: false,
                    child_care_contact_info: '',
                    child_care_institution:'',
                    additional_message: '',
                    date_created: new Date(),
                    status: 1
                },
                citizenshipObject:{
                    id: 0,
                    country_code: -1,
                    descriptor: '',
                    descriptor_english: ''
                },
                countries:[],
                citizenships:[],
                primarySchools:[],
                primarySchoolObject:{
                    id:null,
                    name:''
                },
                flatpickrConfig: { altInput: true, dateFormat: 'Z', altFormat: 'd. m. Y', locale: { firstDayOfWeek: 1}},
                genders: [{text: 'Moški', value: 1}, {text: 'Ženska', value: 2}],
                paymentOptions: [
                    //   {text: 'Gotovina', value: 1},
                    // {text: 'Plačilna kartica', value: 2},
                    {text: 'Nakazilo na TRR po predračunu', value: 3},
                    {text: 'SEPA', value: 4}
                ],
                ownDoctorEntry:true,
                numberOfInstallmentsTypeOne: [1],
                numberOfInstallmentsTypeTwo: [1, 2, 3, 4, 5, 6],
                directDebitDays: [8, 18, 28],
                dietaryTypes: [{text: 'Drugo', value: 1}, {text: 'Vegetarijanska', value: 2}, {text: 'Celiakija', value: 3}, {text: 'Brez laktoze', value: 4}, {text: 'Brez posebnosti', value: 5}],
                specialNeedsOrderTypes: [{text: 'Da', value: true}, {text: 'Ne', value: false}],
                swimmingSkillsTypes: [{text: 'Zna plavati', value: 1}, {text: 'Slab plavalec', value: 2}, {text: 'Ne zna plavati', value: 3}],
                restrainingOrderTypes: [{text: 'Ne', value: 1}, {text: 'Z materjo', value: 2}, {text: 'Z očetom', value: 3}, {text: 'Z obema', value: 4}],
                childLivesInInstitution: [{text: 'Da', value: true}, {text: 'Ne', value: false}],
                availableTermsUnfiltered: [],
                municipalities: [{text: 'Ljubljana', value: 1}, {text: 'Maribor', value: 2}],
                termTypes: [{text: 'VIRC Poreč - Obmorsko letovanje', value: 1}, {text: 'DMZ Pohorje - Višinsko letovanje', value: 2}],
                submissionTypes: [
                    {text: 'SUBVENCIONIRANO LETOVANJE', value: 3},
                    {text: 'SUBVENCIONIRANO ZDRAVSTVENO LETOVANJE', value: 4},
                    {text: 'ZDRAVSTVENO LETOVANJE', value: 2},
                    {text: 'SAMOPLAČNIŠKO LETOVANJE', value: 1}
                ],
                parentTypes: [
                    {text: 'Oče', value: 2},
                    {text: 'Mati', value: 1},
                    {text: 'Skrbnik / Skrbnica', value: 3},
                    {text: 'Rejnik / Rejnica', value: 4},
                    {text: 'Ni podatka', value: 5}
                ],
                document_types: [
                    {text: 'Osebna', value: 1},
                    {text: 'Potni list', value: 2}
                ],
                doctors:[],
                doctorObject:{
                    first_name:'',
                    last_name:'',
                    title_1:''
                },
                school_types: [
                    {text: 'Predšolsko', value: 1},
                    {text: 'Osnovna šola', value: 2},
                    {text: 'Srednja šola', value: 3}
                ],
                parent_statuses: [
                    {text: 'Zaposlen', value: 1},
                    {text: 'Nezaposlen', value: 2},
                    {text: 'Drugo', value: 3}
                ],
                choiceTypes: [{text: 'Da', value: true}, {text: 'Ne', value: false}],
                hasSpecialCharacteristics: true,
                hasDietaryRestriction: true,
                hasDisease: true,
                hasDiseaseStates:true,
                hasPeculiarities: true,

                childAllowanceFiles: [],
                restrainingOrderFiles: [],
                showSuccessCard: false,
                selectedMunicipality: null,
                postcodes:[],
                slovenia :'slovenija',
                // captcha
                turnstileSiteKey: '',

                required,
                email,
                emso_rule,
                tax_rule,
                max_text,
                min_text,

                selectedTermErrorMessage: '',
                widgetID:'',
                showTermSelection:false,
                currentTimestamp:'',
                timestampInterval: null
            }
        },
        computed: {
            availableTermsGrouped() {
                const grouped = {}
                const terms = this.availableTerms
                for (const term of terms) {
                    const isParentTerm = term.parent_id <= 0
                    let id = ''

                    // Child term, that has parent_id (it's not 0 or -1)
                    if (term.is_queue && term.parent_id > 0) {
                        id = term.parent_id
                    } else if (isParentTerm) {
                        id = term.id
                    }

                    if (!id) continue

                    if (!grouped[id]) grouped[id] = []
                    grouped[id].push(term)
                }
                return grouped
            },
            availableTerms() {
                return this.availableTermsUnfiltered.filter(t => t.type === this.submissionObject.term_type)
            },
            municipalityHasDescription() {
                const municipality = this.municipalities.find(f => f.id === this.submissionObject.municipality_id)
                if (!municipality) return false
                return !!municipality.description
            },
            removeLastType() {
                return this.parentTypes.filter(f => f.value !== 5)
            },
            municipalitiesOptions() {
                let tmp = this.municipalities.slice()
                const obcinaVTujiniIDX = tmp.findIndex(m => m.name && m.name.toLowerCase().trim() === 'Obcina v tujini'.toLowerCase().trim())
                if (obcinaVTujiniIDX !== -1) {
                    const obcinaVTujini = tmp[obcinaVTujiniIDX]
                    tmp.splice(obcinaVTujiniIDX, 1)
                    if (this.submissionObject.submission_type === 1) {
                        tmp = [obcinaVTujini, ...tmp]
                    }
                }
                return tmp
            },
            isPublic() {
                return this.currentTimestamp > 1715590800 //Mon May 13 2024 11:00:00 GMT+0200 (Central European Summer Time)
            }
        },
        methods: {
            getParentTypeText(value) {
                return this.parentTypes.find(f => f.value === value).text
            },
            handleOtherDietaryRestrictions() {
                if (this.submissionObject.dietary_type !== 5) {
                    this.submissionObject.other_dietary_restriction = ''
                }
            },
            handleCitizenship() {
                this.submissionObject.citizenship = this.citizenshipObject.descriptor
                this.submissionObject.citizenship_code = this.citizenshipObject.country_code
                this.submissionObject.citizenship_id    = this.citizenshipObject.id
            },
            handlePrimarySchool() {
                this.submissionObject.school_id = this.primarySchoolObject.id
                this.submissionObject.school = this.primarySchoolObject.name
            },
            onSetMotherAddressData() {
                this.$refs['mother-address'].transfer = true
                this.submissionObject.mother_data.address = JSON.parse(JSON.stringify(this.submissionObject.address))
            },
            onSetFatherAddressData() {
                this.$refs['father-address'].transfer = true
                this.submissionObject.father_data.address = JSON.parse(JSON.stringify(this.submissionObject.address))
            },
            onSetPayeeData(from) {
                this.$refs['payee-address'].transfer = true
                let firstName = ''
                let lastName = ''
                let address = {
                    street: '',
                    street_number: '',
                    zip: '',
                    city: '',
                    country: ''
                }
                let email = ''
                if (from === 'mother') {
                    firstName = this.submissionObject.mother_data.first_name
                    lastName = this.submissionObject.mother_data.last_name
                    address = JSON.parse(JSON.stringify(this.submissionObject.mother_data.address))
                    email = this.submissionObject.mother_data.email
                } else if (from === 'father') {
                    firstName = this.submissionObject.father_data.first_name
                    lastName = this.submissionObject.father_data.last_name
                    address = JSON.parse(JSON.stringify(this.submissionObject.father_data.address))
                    email = this.submissionObject.father_data.email
                }
                this.submissionObject.payee_first_name = firstName
                this.submissionObject.payee_last_name = lastName
                this.submissionObject.payee_address = address
                this.submissionObject.payee_email = email
            },
            setReachablePhoneNumber(from) {
                if (from === 'mother') this.submissionObject.reachable_phone_number = this.submissionObject.mother_data.phone_number
                else if (from === 'father') this.submissionObject.reachable_phone_number = this.submissionObject.father_data.phone_number
            },
            setReachableEmail(from) {
                if (from === 'mother') this.submissionObject.reachable_parent_email = this.submissionObject.mother_data.email
                else if (from === 'father') this.submissionObject.reachable_parent_email = this.submissionObject.father_data.email
                else if (from === 'payee') this.submissionObject.reachable_parent_email = this.submissionObject.payee_email
            },
            formatDate(date) {
                return this.$dayjs(date).format('DD.MM.YYYY')
            },
            validateStepOne() {

                if (!this.selectedMunicipality) {
                    this.$printWarning('Polje "Občina otrokovega stalnega prebivališča" je obvezno ')
                    return false
                }

                const requiresFiles = this.submissionObject.submission_type === 3 || this.submissionObject.submission_type === 4
                if (requiresFiles && this.selectedMunicipality.description === '') return false

                // v-for with b-form-radio doesn't work with vee-validate. that's why I'm checking if term_id is invalid (0 or -1, which is set as default value when page loads)
                this.selectedTermErrorMessage = ''
                if (!this.submissionObject.term_id || this.submissionObject.term_id < 0) {
                    this.selectedTermErrorMessage = 'Polje Željena izmena je obvezno'
                    return false
                }
                return this.$refs.stepOneValidation.validate()
            },
            validateStepTwo() {

                if (this.submissionObject.address.country === '') {
                    this.$printWarning('Polje država je obvezno ')
                    return false
                } else if (this.submissionObject.address.country.toLowerCase().trim() === this.slovenia && !this.submissionObject.address.zip_id) {
                    this.$printWarning('Polje pošta je obvezno ')
                    return false
                } else if (this.submissionObject.school_type === 2 && !this.submissionObject.school_id) {
                    this.$printWarning('Polje osnovna šola je obvezno ')
                    return false
                } else if (!this.submissionObject.citizenship_id) {
                    this.$printWarning('Polje državljanstvo je obvezno ')
                    return false
                }

                return this.$refs.stepTwoValidation.validate()
            },
            validateStepThree() {
                if (this.submissionObject.mother_data.parent_exists && this.submissionObject.mother_data.address.country === '') {
                    this.$printWarning('Polje država je obvezno ')
                    return false
                } else if (this.submissionObject.mother_data.address.country.toLowerCase().trim() === this.slovenia && !this.submissionObject.mother_data.address.zip_id) {
                    this.$printWarning('Polje pošta je obvezno ')
                    return false
                } else if (this.submissionObject.father_data.parent_type !== 5 && this.submissionObject.father_data.address.country === '') {
                    this.$printWarning('Polje država je obvezno ')
                    return false
                } else if (this.submissionObject.father_data.address.country.toLowerCase().trim() === this.slovenia && !this.submissionObject.father_data.address.zip_id) {
                    this.$printWarning('Polje pošta je obvezno ')
                    return false
                }
                return this.$refs.stepThreeValidation.validate()
            },
            validateStepFour() {
                if (this.submissionObject.payee_address.country === '') {
                    this.$printWarning('Polje država je obvezno ')
                    return false
                } else if (this.submissionObject.payee_address.country.toLowerCase().trim() === this.slovenia && !this.submissionObject.payee_address.zip_id) {
                    this.$printWarning('Polje pošta je obvezno ')
                    return false
                }
                return this.$refs.stepFourValidation.validate()
            },
            validateStepFive() {
                return this.$refs.stepFiveValidation.validate()
            },
            validateStepSix() {
                return this.$refs.stepSixValidation.validate()
            },
            validateStepSeven() {
                return this.$refs.stepSevenValidation.validate()
            },
            async uploadDocuments() {
                try {
                    const childAllowancePromises = []
                    const restrainingOrderPromises = []

                    for (const file of this.childAllowanceFiles) {
                        const formData = new FormData()
                        formData.append('file', file)

                        const loadPromise = this.$http.post('/api/public/v1/upload_file', formData, {headers: {'Content-Type': 'multipart/form-data'}})
                        childAllowancePromises.push(loadPromise)
                    }

                    for (const file of this.restrainingOrderFiles) {
                        const formData = new FormData()
                        formData.append('file', file)

                        const loadPromise = this.$http.post('/api/public/v1/upload_file', formData, {headers: {'Content-Type': 'multipart/form-data'}})
                        restrainingOrderPromises.push(loadPromise)
                    }

                    const responses = await Promise.all([...childAllowancePromises, ...restrainingOrderPromises])


                    const getFileName = (files) => {
                        return files.map(f => f.data)
                    }

                    return {
                        childAllowance: getFileName(responses.slice(0, childAllowancePromises.length)),
                        restrainingOrder: getFileName(responses.slice(childAllowancePromises.length))
                    }

                } catch (e) {
                    this.$printError('Prišlo je do napake pri nalaganju datotek')

                    return {
                        childAllowance: [],
                        restrainingOrder: []
                    }
                }

            },
            async onSubmitClicked() {

                this.selectedTermErrorMessage = ''
                if (!this.submissionObject.term_id || this.submissionObject.term_id < 0) {
                    this.selectedTermErrorMessage = 'Polje Željena izmena je obvezno'
                    return false
                }

                try {
                    this.$store.commit('app/START_LOADING')

                    const {childAllowance, restrainingOrder} = await this.uploadDocuments()

                    this.submissionObject.child_allowance_document_names = childAllowance
                    this.submissionObject.restraining_order_attachment_names = restrainingOrder

                    if (!this.hasSpecialCharacteristics) this.submissionObject.special_characteristics = ''
                    if (!this.hasDietaryRestriction) this.submissionObject.dietary_restriction = ''
                    if (!this.hasDisease) this.submissionObject.disease = ''
                    if (!this.hasPeculiarities) this.submissionObject.peculiarities = ''
                    if (!this.submissionObject.special_needs_order) this.submissionObject.special_needs_description = ''

                    const turnstileResponse = document.getElementsByName('cf-turnstile-response')
                    if (!turnstileResponse || turnstileResponse.length !== 1) {
                        this.$printError('Pri reševanju captche je prišlo do napake. Prosimo osvežite stran.')
                        return
                    }

                    const captchaValue = turnstileResponse[0].value
                    const payload = {
                        submission: this.submissionObject,
                        captcha_value: captchaValue
                    }

                    await this.$http.post('/api/public/v1/submit', payload)
                    this.showSuccessCard = true
                } catch (e) {
                    const errorMessage = e.response.data.error
                    if (errorMessage === 'duplicate entry') {
                        this.$printError('Otrok z vnešenim EMŠO-m je že prijavljen na izbrani termin')
                    }  else if (errorMessage === 'no available slots in selected term') {
                        this.$printError('Ni prostih mest za izbran termin')
                        window.turnstile.reset(this.widgetID)
                        await this.loadTerms()
                        this.showTermSelection = true
                        this.submissionObject.term_id = null
                    } else {
                        const err = errorMessage ? `(${errorMessage})` : ''
                        this.$printError(`Prišlo je do napake!\n${err}`)
                    }

                } finally {
                    this.$store.commit('app/STOP_LOADING')

                }
            },
            async loadTerms() {
                try {
                    this.$store.commit('app/START_LOADING')

                    const resTerms = await this.$http.get('/api/public/v1/terms')
                    this.availableTermsUnfiltered = resTerms.data
                } catch (e) {
                    this.$printError(e.response.data)
                } finally {
                    this.$store.commit('app/STOP_LOADING')
                }
            },
            async loadData() {
                try {
                    this.$store.commit('app/START_LOADING')

                    const loadTerms = async() => {
                        const resTerms = await this.$http.get('/api/public/v1/terms')
                        this.availableTermsUnfiltered = resTerms.data
                    }
                    const loadMunicipalities = async() => {
                        const resMunicipalities  = await this.$http.get('/api/public/v1/municipalities')
                        this.municipalities = resMunicipalities.data
                        this.municipalities.sort((a, b) => {
                            const nameA = a.name
                            const nameB = b.name
                            if (nameA < nameB)  return -1
                            if (nameA > nameB) return 1
                            return 0
                        })

                        this.submissionObject.municipality_id = this.municipalities[0].id
                        this.submissionObject.term_type = 1
                        // select available term
                        this.selectFirstAvailableTerm()
                    }

                    const loadCountries = async() => {
                        const resCountries  = await this.$http.get('/api/public/v1/countries')
                        const countries = resCountries.data || []

                        const countrySlovenijaIdx = countries.findIndex(c => c.descriptor.toLowerCase().trim() === this.slovenia)

                        if (countrySlovenijaIdx !== -1) {
                            const countrySlovenija = countries[countrySlovenijaIdx]
                            countries.splice(countrySlovenijaIdx, 1)
                            this.countries = [countrySlovenija, ...countries]
                        } else {
                            this.countries = countries
                        }
                    }

                    const loadCitizenships = async() => {
                        const resCitizenships  = await this.$http.get('/api/public/v1/citizenships')
                        const citizenships = resCitizenships.data || []

                        const citizenshipSlovenijaIdx = citizenships.findIndex(c => c.descriptor.toLowerCase().trim() === this.slovenia)

                        if (citizenshipSlovenijaIdx !== -1) {
                            const citizenshipSlovenija = citizenships[citizenshipSlovenijaIdx]
                            citizenships.splice(citizenshipSlovenijaIdx, 1)
                            this.citizenships = [citizenshipSlovenija, ...citizenships]
                        } else {
                            this.citizenships = citizenships
                        }
                    }

                    const loadSubmissionTypes = async() => {
                        const resSubmissionTypes  = await this.$http.get('/api/public/v1/submission_types')
                        this.submissionTypes = resSubmissionTypes.data || []
                    }

                    const loadPrimarySchools = async() => {
                        const resPrimarySchools  = await this.$http.get('/api/public/v1/primary_schools')
                        this.primarySchools = resPrimarySchools.data || []

                        const osVTujiniIDX = this.primarySchools.findIndex(os => os.name.toLowerCase().trim() === 'OŠ v tujini'.toLowerCase().trim())
                        if (osVTujiniIDX !== -1) {
                            const osVTujini = this.primarySchools[osVTujiniIDX]
                            this.primarySchools.splice(osVTujiniIDX, 1)
                            this.primarySchools = [osVTujini, ...this.primarySchools]
                        }
                    }

                    const loadDoctors = async() => {
                        const resDoctors  = await this.$http.get('/api/public/v1/doctors')
                        this.doctors = resDoctors.data || []
                    }

                    const loadPostCodes = async() => {
                        const resPostcodes  = await this.$http.get('/api/public/v1/post_codes')
                        this.postcodes = resPostcodes.data || []
                    }

                    await Promise.all([loadTerms(), loadMunicipalities(), loadCountries(), loadCitizenships(), loadSubmissionTypes(), loadPrimarySchools(), loadDoctors(), loadPostCodes()])


                } catch (e) {
                    this.$printError(e.response.data)
                } finally {
                    this.$store.commit('app/STOP_LOADING')
                }
            },
            selectFirstAvailableTerm() {
                for (const t of this.availableTerms) {
                    if (t && t.available_slots > 0) {
                        this.submissionObject.term_id = t.id
                        break
                    }
                }
            },
            initTurnstile() {
                const thisIns = this
                if (!thisIns.turnstileSiteKey || thisIns.turnstileSiteKey === '') return

                window.onloadTurnstileCallback = function() {
                    thisIns.widgetID = window.turnstile.render('#cf-turnstile', {
                        theme: 'light',
                        sitekey: thisIns.turnstileSiteKey,
                        callback() {
                            thisIns.isCaptchaLoaded = true
                        }
                    })
                }
            },
            searchDoctors(option, label, search) {
                return `${option.first_name} ${option.last_name} ${option.title_1} ${option.title_2}`.toLowerCase().includes(search.toLowerCase())
            }
        },
        watch: {
            'hasOwnDoctor'() {
                this.submissionObject.doctor_id = null
                this.submissionObject.first_name = ''
                this.submissionObject.last_name = ''
                this.submissionObject.title_1 = ''
                this.submissionObject.title_2 = ''
            },
            'submissionObject.emso'() {
                //  const emso = this.submissionObject.emso
                //if (!emso || emso.length !== 13) return
                // const genderNumber = emso[9]
                // if (['0', '1', '2', '3', '4'].includes(genderNumber)) this.submissionObject.gender = 1
                // else this.submissionObject.gender = 2
            },
            'submissionObject.term_type'() {
                if (!this.availableTerms || !this.availableTerms.length) return
                this.selectedTermErrorMessage = ''
                this.selectFirstAvailableTerm()
            },
            'submissionObject.payment_type'(newVal, oldVal) {
                // if user changed from "SEPA direktna bremenitev" to anything else, change "Število obrokov" to 1
                // reset trr, bank name and debit_day
                if (oldVal === 4) {
                    this.submissionObject.number_of_installments = this.numberOfInstallmentsTypeOne[0]
                    this.submissionObject.direct_debit_trr = ''
                    this.submissionObject.direct_debit_bank_name = ''
                    this.submissionObject.direct_debit_day = 0
                }
                if (newVal === 4) {
                    this.submissionObject.direct_debit_day = this.directDebitDays[0]
                }
            },
            'submissionObject.restraining_order_type'() {
                // remove files if "restraining order type" is "Nič"
                if (this.submissionObject.restraining_order_type === 1) {
                    this.restrainingOrderFiles = []
                }
            },
            'submissionObject.submission_type'() {
                // remove files if "submission type" is "Subvencionirano s strani občine" or "Subvencionirano s strani občine in zdravstva"
                if (this.submissionObject.submission_type !== 3 || this.submissionObject.submission_type !== 4) {
                    this.childAllowanceFiles = []
                }
            },
            '$store.state.app.turnstileSiteKey'(val) {
                if (val && val !== '') {
                    this.turnstileSiteKey = val
                    this.initTurnstile()
                }
            },
            'selectedMunicipality'(val) {
                this.submissionObject.municipality_id = val.id
            },
            'submissionObject.father_data.parent_type'(val) {
                if (val === 5) {
                    this.submissionObject.father_data = {
                        first_name: '',
                        last_name: '',
                        address:{
                            street: '',
                            street_number: '',
                            zip_id:null,
                            zip: '',
                            city: '',
                            country: '',
                            country_id: null
                        },
                        phone_number: '',
                        email: '',
                        parent_status:null,
                        parent_type:val
                    }
                }
            }
        },
        async mounted() {

            this.timestampInterval =  window.setInterval(() => {
                this.currentTimestamp = Number((new Date().getTime() / 1000).toFixed(0))

            }, 1000)


            this.turnstileSiteKey = this.$store.state.app.turnstileSiteKey
            this.initTurnstile()

            this.submissionObject.number_of_installments = this.numberOfInstallmentsTypeOne[0]
            await this.loadData()
        },
        beforeDestroy() {
            if (this.timestampInterval) {
                window.clearInterval(this.timestampInterval)
            }
        }
    }
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '~vue-form-wizard/dist/vue-form-wizard.min.css';
//@import '~@core/scss/base/pages/app-ecommerce.scss';
//@import '@core/scss/vue/libs/vue-wizard.scss';
//@import '@core/scss/vue/libs/vue-select.scss';
//@import 'themify-icons/themify-icons';
</style>
<style lang="scss" scoped>
.checkout-form-wizard ::v-deep {
  .wizard-tab-content {
    box-shadow: none !important;
    background: transparent !important;
    padding: 0;
  }
}
</style>
<style>
.wizard-card-footer {
  box-shadow: none !important;
}
.mt-05 {
  margin-top: 0.5rem !important;
}
.h-fit-content {
  height: fit-content;
}
.minWidth {
  width: 15rem;
}
</style>
