<template>
    <validation-observer :ref="getValidationObserverName()">
        <b-badge variant="warning">Vpišite stalni naslov bivališča</b-badge>
        <b-row class="pt-1">
            <b-col cols="12" md="6">
                <validation-provider #default="{ errors }" name="Ulica" :rules="!disabled ? 'required':''" >
                    <b-form-group label="Ulica">
                        <b-form-input v-model="address.street" :disabled="disabled"></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                </validation-provider>

            </b-col>
            <b-col cols="12" md="6">
                <validation-provider #default="{ errors }" name="Hišna številka" :rules="!disabled ? 'required':''" >
                    <b-form-group label="Hišna številka">
                        <b-form-input v-model="address.street_number" :disabled="disabled"></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                </validation-provider>

            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12" md="4">

                <b-form-group label="Država">
                    <validation-provider #default="{ errors }" name="Država" rules="required" >

                        <vue-select :options="countries" v-model="countryObject"  label="descriptor"
                                    :clearable="false" :filterable="true" :searchable="true" @input="handleCountry" :disabled="disabled" ></vue-select>

                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>

                </b-form-group>

            </b-col>

            <b-col cols="12" md="5" v-if="showSelect">
                <b-form-group label="Pošta">
                    <validation-provider #default="{ errors }" name="Pošta" :rules="!disabled ? 'required':''" >
                        <vue-select v-model="postcodeObject" :options="postcodes" label="zip"
                                    :clearable="false" :filterable="true" :filterBy="filterFallback" :searchable="true"  @input="handlePostcode" :disabled="disabled" >
                            <template #option="{zip,name}">
                                <span v-if="zip || name">{{`${zip} - ${name}`}}</span>
                            </template>
                            <template #selected-option="{zip,name}">
                                <span v-if="zip || name">{{`${zip} - ${name}`}}</span>
                            </template>
                        </vue-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
            </b-col>
            <template v-else>
                <b-col cols="12" md="4">
                    <validation-provider #default="{ errors }" name="Poštna številka" :rules="!disabled ? 'required':''" >
                        <b-form-group label="Poštna številka">
                            <b-form-input v-model="address.zip" :disabled="disabled"></b-form-input>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                    </validation-provider>

                </b-col>
                <b-col cols="12" md="4">
                    <validation-provider #default="{ errors }" name="Pošta" :rules="!disabled ? 'required':''" >
                        <b-form-group label="Pošta">
                            <b-form-input v-model="address.city" :disabled="disabled"></b-form-input>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                    </validation-provider>
                </b-col>
            </template>
        </b-row>
    </validation-observer>

</template>
<script>
    import { BFormGroup, BFormInput, BRow, BCol, BBadge} from 'bootstrap-vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required } from '@validations'
    import VueSelect from 'vue-select'

    export default {
        components: {
            VueSelect,
            BFormInput,
            BFormGroup,
            BRow,
            BCol,
            ValidationProvider,
            ValidationObserver,
            BBadge
        },
        props: {
            address: {
                type: Object,
                default: () => {
                    return {
                        street: '',
                        street_number: '',
                        zip: '',
                        zip_id:-1,
                        country_id: '',
                        city: ''
                    }
                }
            },
            countries: {
                type: Array,
                default:() => []
            },
            postcodes: {
                type: Array,
                default:() => []
            },
            name: {
                type: String
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                required,
                countryObject:{
                    id:null,
                    descriptor:''
                },
                postcodeObject:{
                    id:null,
                    name:'',
                    zip:''
                },
                transfer:false
            }
        },
        methods: {
            async validate() {
                return await this.$refs[this.getValidationObserverName()].validate()
            },
            getValidationObserverName() {
                return `${this.name}-addressSubmission`
            },
            handleCountry() {
                this.address.country_id = parseInt(this.countryObject.id)
                this.address.country = this.countryObject.descriptor
            },
            handlePostcode() {
                this.address.zip_id = parseInt(this.postcodeObject.id)
                this.address.zip = this.postcodeObject.zip
                this.address.city = this.postcodeObject.name
            },
            filterFallback(option, label, search) {
                return option.zip.includes(search) || option.name.toLowerCase().includes(search.toLowerCase())
            }
        },
        computed: {
            showSelect() {
                const slovenia = 'Slovenija'.toLowerCase().trim()
                return this.countryObject.descriptor.toLowerCase().trim() === slovenia 
            }
        },
        watch: {
            'address.country'() {
                if (this.transfer) return
                this.address.zip = ''
                this.address.city = ''
                this.address.zip_id = null
                this.postcodeObject = { id: null, name: '', zip: '' }
                this.transfer = false
            },
            'address'() {
                this.countryObject = this.countries.find(country => country.id === this.address.country_id) || { id: null, descriptor: '' }
                this.postcodeObject = { id: this.address.zip_id, name: this.address.city, zip: this.address.zip }
            }}
    }
</script>
