var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:_vm.getValidationObserverName()},[_c('b-badge',{attrs:{"variant":"warning"}},[_vm._v("Vpišite stalni naslov bivališča")]),_c('b-row',{staticClass:"pt-1"},[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Ulica","rules":!_vm.disabled ? 'required':''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Ulica"}},[_c('b-form-input',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.address.street),callback:function ($$v) {_vm.$set(_vm.address, "street", $$v)},expression:"address.street"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Hišna številka","rules":!_vm.disabled ? 'required':''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Hišna številka"}},[_c('b-form-input',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.address.street_number),callback:function ($$v) {_vm.$set(_vm.address, "street_number", $$v)},expression:"address.street_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Država"}},[_c('validation-provider',{attrs:{"name":"Država","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-select',{attrs:{"options":_vm.countries,"label":"descriptor","clearable":false,"filterable":true,"searchable":true,"disabled":_vm.disabled},on:{"input":_vm.handleCountry},model:{value:(_vm.countryObject),callback:function ($$v) {_vm.countryObject=$$v},expression:"countryObject"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.showSelect)?_c('b-col',{attrs:{"cols":"12","md":"5"}},[_c('b-form-group',{attrs:{"label":"Pošta"}},[_c('validation-provider',{attrs:{"name":"Pošta","rules":!_vm.disabled ? 'required':''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-select',{attrs:{"options":_vm.postcodes,"label":"zip","clearable":false,"filterable":true,"filterBy":_vm.filterFallback,"searchable":true,"disabled":_vm.disabled},on:{"input":_vm.handlePostcode},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var zip = ref.zip;
var name = ref.name;
return [(zip || name)?_c('span',[_vm._v(_vm._s((zip + " - " + name)))]):_vm._e()]}},{key:"selected-option",fn:function(ref){
var zip = ref.zip;
var name = ref.name;
return [(zip || name)?_c('span',[_vm._v(_vm._s((zip + " - " + name)))]):_vm._e()]}}],null,true),model:{value:(_vm.postcodeObject),callback:function ($$v) {_vm.postcodeObject=$$v},expression:"postcodeObject"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,347461521)})],1)],1):[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Poštna številka","rules":!_vm.disabled ? 'required':''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Poštna številka"}},[_c('b-form-input',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.address.zip),callback:function ($$v) {_vm.$set(_vm.address, "zip", $$v)},expression:"address.zip"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Pošta","rules":!_vm.disabled ? 'required':''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Pošta"}},[_c('b-form-input',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.address.city),callback:function ($$v) {_vm.$set(_vm.address, "city", $$v)},expression:"address.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }