<template>
    <validation-observer :ref="getValidationObserverName">
        <b-row>
            <b-col>
                <b-form-group :label="label" description="Dovoljeni formati: pdf, jpg, jpeg, png, webp. Velikost datoteke je omejena na 10MB">
                    <validation-provider #default="{ errors }" :name="validationName" rules="required">
                        <b-form-file v-model="filesInternal" @input="onFileChanged" multiple :file-name-formatter="formatNames"/>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <div v-if="filesInternal && filesInternal.length > 0" class="mb-2">
                    <small>Izbrane datoteke</small>
                    <div v-for="(file, index) of filesInternal" :key="`file_${index}`" class="text-primary">
                        <div class="flex items-center gap-1">
                            <a :href="createURLForFile(file)" target="_blank">{{file.name}}</a>
                            <span class="ml-1 cursor-pointer" @click="filesInternal.splice(index, 1)"><fa icon="times"/></span>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
    </validation-observer>
</template>
<script>
    import {BRow, BCol, BFormGroup, BFormFile} from 'bootstrap-vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required } from '@validations'

    export default {
        components:{BRow,
                    BCol,
                    BFormGroup,
                    BFormFile,
                    ValidationProvider,
                    ValidationObserver},
        props: {
            label: {
                type: String
            },
            validationName: {
                type: String
            },
            name: {
                type: String
            },
            files: {
                type: Array
            }
        },
        data() {
            return {
                allowedFileTypes: ['image/jpeg', 'application/pdf', 'image/png', 'image/webp'],
                required,
                filesInternal: []
            }
        },
        methods: {
            onFileChanged() {
                this.filesInternal = this.filesInternal.filter(f => {
                    const isFileTypeAllowed = this.allowedFileTypes.includes(f.type)
                    const isSizeAllowed = f.size < 10_485_760

                    if (!isFileTypeAllowed) this.$printWarning(`Datoteka ${f.name} je napačnega formata!`)
                    if (!isSizeAllowed) this.$printWarning(`Datoteka ${f.name} presega največjo dovoljeno velikost! (10MB)`)
                    return isSizeAllowed && isFileTypeAllowed
                })

                this.$emit('input', this.filesInternal)
            },
            getValidationObserverName() {
                return `${this.name}-filesComponent`
            },
            formatNames() {
                return this.filesInternal.map(f => f.name).join(', ')
            },
            createURLForFile(file) {
                return URL.createObjectURL(file)
            }
        }
    }
</script>
